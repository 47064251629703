import { all, takeLatest } from 'redux-saga/effects';

//import signUp from './sign-up';
//import confirmRegistration from './confirm-registration';
//import confirmPassword from './confirm-password';
//import resendConfirmationCode from './resend-confirmation-code';
//import signInStart from './sign-in-start';
//import signInComplete from './sign-in-complete';

import initialize from './initialize';

import signOut from './sign-out';
import fetchInvite from './fetch-invite';
//import replyInvite from './reply-invite';
//import oauth from './oauth2-start-flow';

export default function* sagaRoot () {
  yield all([
    takeLatest('INITIALIZE', initialize),

    //takeLatest('SIGN_IN', signIn),
    //takeLatest('SIGN_IN_START', signInStart),
    //takeLatest('SIGN_IN_COMPLETE', signInComplete),
    //takeLatest('SIGN_UP', signUp),
    //takeLatest('CONFIRM_REGISTRATION', confirmRegistration),
    //takeLatest('CONFIRM_PASSWORD', confirmPassword),
    //takeLatest('RESEND_CONFIRMATION_CODE', resendConfirmationCode),

    takeLatest('SIGN_OUT', signOut),

    takeLatest('FETCH_INVITE', fetchInvite),
    //takeLatest('OAUTH', oauth)
  ]);
}
