import React from 'react';
import PropTypes from 'prop-types';
import RedoIcon from '@material-ui/icons/Replay';

import Button from '../Button';
import { Title, Footer } from '../Whitebox';
import { Link, Input } from './styles';

const ErrorContainer = (props) => {
  if (!props.error) {
    return null;
  }

  return (<><small style={{color: 'red'}}>Código inválido</small><br/></>)
}

const ConfirmAccountForm = (props) => {

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange( {
        email: props.data.email,
        code: e.target.value
      });
    }
  }

  const handleResendCode = (e) => {
    if (props.resendingCode) {
      return false;
    }

    if (props.onResendCode) {
      props.onResendCode(e);
    }
  }

  const handleSubmit = (e) => {
    if (props.onSubmit) {
      props.onSubmit(e);
    }
  }

  return (
    <>
      <Title>Confirme sua conta</Title>
      <span>Sua conta ainda não foi confirmada, para continuar insira o código de confirmação enviado para seu email de cadastro.</span>
      <br/>
      <br/>
      
      <small>Email</small><br/>
      <Input value={props.data.email} disabled /><br/>

      <small>Código</small><br/>
      <Input
        placeholder="Digite o código"
        value={props.data.code || ''}
        onChange={handleChange}
        disabled={props.loading}
      />

      <br/>
      <ErrorContainer error={props.error} />
      <br/>

      <Footer>
        <Button color="primary" onClick={handleSubmit}>
          { props.sending === false ? 'Confirmar' : 'Confirmando...' }
        </Button>
      </Footer>
      <br />

      <small>
        <span>Não recebeu o código? </span>
        <Link onClick={handleResendCode}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <RedoIcon style={{fontSize: '14px'}} />
            <span>
              { !props.resendingCode ? 'Enviar novamente' : 'Reenviando...' }
            </span>
          </div>
        </Link>
      </small>
    </>
  )
}

ConfirmAccountForm.propTypes = {
  sending: PropTypes.bool,
  resendingCode: PropTypes.bool,
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onResendCode: PropTypes.func
}

export default ConfirmAccountForm;