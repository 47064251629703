import React from 'react';
import { withRouter } from 'react-router';
import { Box as Layout, Title } from '../../components/Whitebox';

const SignOutPage = (props) => {
  React.useEffect(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    //props.history.push('/sign-in');
    window.location.href = process.env.REACT_APP_MAP_ENDPOINT;
  });

  return (
    <Layout>
      <Title>Você se desconectou</Title>
      <span>Você será redirecionado para a página de inicial em alguns instantes.</span>
    </Layout>
  )
};

export default withRouter(SignOutPage);