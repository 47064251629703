import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'query-string';
import session from '../../shared/session';

// Components
import { Box, Title } from '../../components/Whitebox';
import Button from '../../components/Button';

const withUser = connect((state) => {
  return {
    user: state.user
  }
});

const LoadingScreen = (props) => {
  return (
    <Box>
      <Title>Carregando...</Title>
    </Box>
  )
}

const AllowedScreen = (props) => {
  const getAuthorizedUrl = () => {
    var searchParams = {
      accessToken: session.accessToken,
      refreshToken: localStorage.getItem('refreshToken')
    };

    if (props.redirectUri && props.redirectUri.length > 0) {
      searchParams.redirectUri = props.redirectUri;
    }

    return `${props.callbackUri}?${qs.stringify(searchParams)}`;
  }

  /**
   * Handle authorize click
   * @param {Event} event
   */
  const handleAuthorize = () => {  
    window.location.href = getAuthorizedUrl();
  }

  React.useEffect(() => {
    setTimeout(() => {
      handleAuthorize();
    }, 1500);
  });

  return (
    <Box>
      <Title>Redirecionando...</Title>
      <span>Seu login foi efetuado com sucesso! você será redirecionando dentro de alguns instantes.</span><br/><br/>

      <small>Caso seu navegador não te redirecione automaticamente, clique no botão abaixo para continuar.</small><br/>
      <Button onClick={handleAuthorize}>Continuar</Button>
    </Box>
  )
}

const Container = withUser((props) => {
  const { clientId, callbackUri, redirectUri } = props;

  if (!props.user.touched || props.user.loading) {
    return (<LoadingScreen />);
  }

  if (!props.user.connected) {
    return (<Redirect to={`/sign-in?${qs.stringify({ redirect: window.location.href })}`} />);
  }

  return (
    <AllowedScreen 
      clientId={clientId}
      callbackUri={callbackUri}
      redirectUri={redirectUri}
    />
  )
});

const OAuthPage = (props) => {
  var elementProps = {
    clientId: null,
    callbackUri: null,
    redirectUri: null
  };

  var queryStrings = new URLSearchParams(props.location.search);

  if (!queryStrings.has('clientId')) {
    return (<span>Parâmetro "clientId" é obrigatório.</span>);
  }

  if (!queryStrings.has('callbackUri')) {
    return (<span>Parâmetro "callbackUri" é obrigatório</span>)
  }

  if (queryStrings.has('redirectUri')) {
    elementProps.redirectUri = queryStrings.get('redirectUri');
  }

  elementProps.callbackUri = queryStrings.get('callbackUri');
  elementProps.clientId = queryStrings.get('clientId');

  return React.createElement(Container, elementProps, null);
}

export default withRouter(OAuthPage);