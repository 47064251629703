import styled from 'styled-components';

export const Warning = styled.div`
  padding: 7px;
  border: 1px solid #e0c643;
  background-color: #f8d838;
  color: #5d5844;
  border-radius: 4px;
  margin: 7px 0px;
  font-size: 80%;
`