import React from 'react';
import { withRouter, Redirect } from 'react-router';

// Components
import { Box as Layout } from '../../components/Whitebox';
import { Title } from '../../components/Whitebox';
import Button from '../../components/Button';
import ConfirmUserForm from '../../components/ConfirmUserForm';
import RedirectUri from '../../components/RedirectUri';

import api from '../../helpers/api';

const CongratsPage = (props) => {
  const [ toSignIn, setToSignIn ] = React.useState(false);

  if (toSignIn) {
    var query = new URLSearchParams();
    query.set('email', props.email);
    return (<Redirect to={`/sign-in?${query.toString()}`} />);
  }

  const handleClick = () => {
    setToSignIn(true);
  }

  return (
    <Layout>
      <Title>Conta confirmada!</Title>
      <span>Sua conta foi confirmada, agora você já pode começar a usar nossa plataforma.</span>
      <br/>
      <br/>

      <Button onClick={handleClick}>Entrar</Button>
    </Layout>
  )
}

const ConfirmPage = (props) => {
  const search = new URLSearchParams(props.location.search);

  const [ customRedirect, setCustomRedirect ] = React.useState(false);
  const [ valid, setValid ] = React.useState(false);
  const [ sending, setSending ] = React.useState(false);
  const [ resendingCode, setResendingCode ] = React.useState(false);
  const [ error, setError ] = React.useState(null);

  const [ form, setForm ] = React.useState({
    email: search.get('email'),
    code: search.get('code')
  });

  const confirmUserProps = {
    sending: sending,
    resendingCode: resendingCode,
    data: form,
    error: error,
    onChange: (newData) => {
      setForm(newData);
    },
    onSubmit: async () => {
      setSending(true);

      try {
        var submitCodeParams = {
          email: form.email,
          code: form.code
        };

        await api.submitConfirmationCode(submitCodeParams);

        if (search.has('redirect')) {
          setCustomRedirect(true);
        }

        setValid(true);
      } catch (e) {
        setError(e);
      }

      setSending(false);
    },
    onResendCode: async () => {
      setResendingCode(true);

      var resendConfirmationCodeParams = {
        email: form.email
      };

      try {
        await api.resendConfirmationCode(resendConfirmationCodeParams);
      } catch (e) {
        // Do nothing
      }

      setResendingCode(false);
    }
  };

  if (customRedirect) {
    return (<RedirectUri to={search.get('redirect')} />);
  }

  if (valid) {
    return (<CongratsPage email={form.email} />);
  }

  return (
    <Layout>
      <ConfirmUserForm {...confirmUserProps} />
    </Layout>
  );
}

export default withRouter(ConfirmPage);