import { Auth, Hub } from 'aws-amplify';

export default async ({ payload }) => {
  var response = {
    email: payload.email,
    error: null
  };

  try {
    await Auth.resendSignUp(payload.email); // Resend confirmation code
  } catch (e) {
    response.error = e;
  }

  Hub.dispatch('resend_email_confirmation_code_response', response);
}