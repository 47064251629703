import React from 'react';
import PropTypes from 'prop-types';

// Components
import TextField from '../TextField';
import Button from '../Button';
import Alert from '../ErrorSpan';
import { Body, Footer, Title } from '../Whitebox';

const getValueForInput = (name, data) => {
  return data[name] || '';
}

const getErrorForInput = (name, errors, touched) => {
  if (!touched) {
    return null;
  }

  var error = errors[name];

  if (Array.isArray(error)) {
    return error[0];
  }

  return error;
}

const SignUpForm = (props) => {

  const _handleSubmit = (e) => {
    if (props.onSubmit) {
      props.onSubmit(props.data, e);
    }
  }

  const _handleChange = (target) => {
    return (e) => {
      const newState = { ...props.data };
      var value = e.target.value;

      if (target === 'email') {
        value = value.toLowerCase();
      }

      newState[target] = value;

      if (props.onChange) {
        props.onChange(newState);
      }
    }
  }

  const _handleBlur = (target) => {
    return (e) => {
      if (props.onBlur) {
        props.onBlur(target, e);
      }

      var input = props[`${target}Input`];

      if (input && input.onBlur) {
        input.onBlur(target, e);
      }

      /*
      const newState = { ...props.touched };
      newState[target] = true;

      if (props.onTouchedChange) {
        props.onTouchedChange(newState);
      }
      */
    }
  }

  const submitButtonProps = {
    color: 'primary',
    type: 'button',
    disabled: Boolean(props.sending) || !Boolean(props.valid),
    onClick: _handleSubmit
  };

  return (
    <>
      <Title>Criar uma conta no <strong>{process.env.REACT_APP_NAME}</strong></Title>
      <Body>
        <TextField
          name="email"
          label="Email"
          type="email"
          value={getValueForInput('email', props.data)}
          error={getErrorForInput('email', props.errors, Boolean(props?.touched?.email))}
          disabled={Boolean(props.emailInput?.disabled) || props.sending }
          onBlur={_handleBlur('email')}
          onChange={_handleChange('email')}
        />

        <TextField
          name="name"
          label="Nome"
          value={getValueForInput('name', props.data)}
          error={getErrorForInput('name', props.errors, Boolean(props?.touched?.name))}
          disabled={props.sending}
          onBlur={_handleBlur('name')}
          onChange={_handleChange('name')}
        />

        <TextField
          name="password"
          label="Senha"
          type="password"
          value={getValueForInput('password', props.data)}
          error={getErrorForInput('password', props.errors, Boolean(props?.touched?.password))}
          disabled={props.sending}
          onBlur={_handleBlur('password')}
          onChange={_handleChange('password')}
        />

        <TextField
          name="passwordConfirm"
          label="Confirmar"
          type="password"
          value={getValueForInput('passwordConfirm', props.data)}
          error={getErrorForInput('passwordConfirm', props.errors, Boolean(props?.touched?.passwordConfirm))}
          disabled={props.sending}
          onBlur={_handleBlur('passwordConfirm')}
          onChange={_handleChange('passwordConfirm')}
        />

        { props.error && (<Alert message={props.error} />) }

        <Footer style={{padding: '4px'}}>
          <Button {...submitButtonProps} >
            { Boolean(props.sending) ? 'Cadastrando...' : 'Cadastrar' }
          </Button>
          { !Boolean(props?.cancelButton?.disabled) && (<Button color="secondary" type="button" onClick={props.onCancel}>Voltar</Button>) }
        </Footer>
      </Body>
    </>
  );
}

SignUpForm.propTypes = {
  valid: PropTypes.bool,
  sending: PropTypes.bool,
  data: PropTypes.object,
  errors: PropTypes.object,
  cancelButton: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

SignUpForm.defaultProps = {
  valid: true,
  sending: false,
  data: {
    email: '',
    password: '',
    passwordConfirm: ''
  },
  touched: {
    email: false,
    password: false,
    passwordConfirm: false
  },
  errors: {},
  cancelButton: {
    disabled: false
  }
}

/*
export default withFormik({
  mapPropsToValues: (props) => {
    return props.data;
  },
  mapPropsToStatus: (props) => {
    return { error: null };
  },
  validate: (values) => {
    return validateForm({
      'name': validateNonNull('name'),
      'email': combineValidators([
        validateNonNull('email'),
        validateEmail('email')
      ]),
      'password': combineValidators([
        validateNonNull('password'),
        validateCharLength('password', 6)
      ]),
      'confirmPassword': combineValidators([
        validateNonNull('confirmPassword'),
        validateMatch('confirmPassword', 'password')
      ])
    }, values);
  },
  validateOnBlur: true
})(SignUpForm);
*/

export default SignUpForm;