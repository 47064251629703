import React from 'react';
import { Provider } from 'react-redux';
import { Hub } from 'aws-amplify';

// Dependencies
import './shared/amplify';
import session from './shared/session';
import store from './store';

// Components
import Routes from './Routes';

// Listeners
import refreshTokenChange from './listeners/refresh-token-change';
import requestAuthenticationListener from './listeners/request-authentication';
import requestTokensListener from './listeners/request-tokens';
import authenticationOkListener from './listeners/authentication-ok';
import authenticationErrListener from './listeners/authentication-err';
import sessionStateChangeListener from './listeners/session-state-change';
import sessionTokensChangeListener from './listeners/session-tokens-change';
import submitEmailConfirmationCodeListener from './listeners/submit-email-confirmation-code';
import resendEmailConfirmationCodeListener from './listeners/resend-email-confirmation-code';
import signUpListener from './listeners/register-account';
import signInListener from './listeners/sign-in';
import signOutListener from './listeners/sign-out';

// Hooks
Hub.listen('refresh_token_change', refreshTokenChange);
Hub.listen('request_tokens', requestTokensListener);
Hub.listen('request_authentication', requestAuthenticationListener);
Hub.listen('authentication_ok', authenticationOkListener);
Hub.listen('authentication_err', authenticationErrListener);
Hub.listen('session_state_change', sessionStateChangeListener);
Hub.listen('session_tokens_change', sessionTokensChangeListener);
Hub.listen('submit_email_confirmation_code', submitEmailConfirmationCodeListener); // confirm_user_email_request
Hub.listen('resend_email_confirmation_code', resendEmailConfirmationCodeListener);
Hub.listen('register_account', signUpListener);
Hub.listen('sign_in', signInListener);
Hub.listen('sign_out', signOutListener);

if (process.env.NODE_ENV === 'development') {
  window.hub = Hub;
}

session.start();

function App () {
  React.useEffect(() => {
    store.dispatch({ type: 'INITIALIZE' });
  });

  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  )
}

export default App;