import React from 'react';

/**
 * With user
 * @param {React.Component} Component
 * @returns {React.Component}
 */
const withUser = (Component) => {
  return (props) => {
    return (<Component {...props} />)
  }
}

export default withUser;