import axios from 'axios';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { authenticate, renewAccessToken, getUser } from './cognito';
import session from '../shared/session';

const cognito = new CognitoIdentityServiceProvider({
  region: 'us-east-1'
});

/**
 * Sign in (login user)
 * @param {Object} params
 * @param {String} params.email
 * @param {String} params.password
 * @returns {Object} tokens
 */
export const signIn = async (params) => {
  const { email, password } = params;
  const auth = await authenticate({ email, password });

  return {
    idToken: auth.AuthenticationResult.IdToken,
    accessToken: auth.AuthenticationResult.AccessToken,
    refreshToken: auth.AuthenticationResult.RefreshToken
  }
}

/**
 * Exchange refresh-token for access-token
 * @param {Object} params
 * @param {Object} params.refreshToken
 * @returns {Object} tokens
 */
export const exchangeRefreshToken = async (params) => {
  const { refreshToken } = params;
  const auth = await renewAccessToken({ refreshToken });

  return {
    idToken: auth.AuthenticationResult.IdToken,
    accessToken: auth.AuthenticationResult.AccessToken,
    refreshToken: refreshToken
  }
}

/**
 * Fetch user (identify user for access-token)
 * @param {Object} params 
 * @param {Object} params.accessToken
 * @returns {Object} user attributes
 */
export const getAccessTokenUser = async (params) => {
  const { accessToken } = params;
  var user = await getUser({ accessToken });
  return user;
}

/**
 * Sign up (register user)
 * @param {Object} params 
 * @returns {Promise}
 */
export const signUp = async (params) => {
  const { email, name, password } = params;

  var signUpParams = {
    ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    Username: email,
    Password: password,
    UserAttributes: [
      {
        Name: 'name',
        Value: name
      }
    ]
  };

  var response = await cognito.signUp(signUpParams).promise();
  return response;
}

/**
 * Submit confirmation code
 * @param {Object} params
 * @param {String} params.email
 * @param {String} params.code
 * @returns {Object}
 */
export const submitConfirmationCode = async (params) => {
  const { email, code } = params;

  const confirmSignUpParams = {
    ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    ConfirmationCode: code,
    Username: email
  }

  var response = await cognito.confirmSignUp(confirmSignUpParams).promise();
  return response;
}

/**
 * Resend confirmation code
 * @param {Object} params
 * @param {String} params.email
 * @returns {Object} response
 */
export const resendConfirmationCode = async (params) => {
  const { email } = params;

  const resendConfirmationCodeParams = {
    ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    Username: email
  }

  var response = await cognito.resendConfirmationCode(resendConfirmationCodeParams).promise();
  return response;
}

/**
 * Check whether account exists
 * @param {Object} params 
 * @param {String} params.username
 * @param {String} params.clientId
 * @returns {Boolean} whether account exists
 */
export const isValidEmail = async function (params) {
  const { email } = params;

  return new Promise((resolve) => {
    var requestParams = {
      email: email,
      password: '123'
    };

    signIn(requestParams)
      .then(() => {
        resolve(true);
      })
      .catch((e) => {
        resolve(e.code !== "UserNotFoundException");
      });
  });
}

export const fetchInvite = async function (params) {
  const { id } = params;

  try {
    var invitationResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/invitations/${id}`);

    if (invitationResponse.data.error) {
      throw new Error(invitationResponse.data.error);
    }

    const { projectId, email, requesterId } = invitationResponse.data.data;
    var requesterResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/${requesterId}`);
    var projectResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/projects/${projectId}`);
    var isUserAlreadyRegistred = await isValidEmail({ email });
    
    return {
      error: null,
      data: {
        invite: invitationResponse.data,
        project: projectResponse.data,
        requester: requesterResponse.data,
        exists: isUserAlreadyRegistred
      }
    }
  } catch (e) {
    return {
      error: e,
      data: null
    }
  }
}

export const answerInvite = async function (params) {
  return new Promise((resolve, reject) => {
    const { id, code } = params;

    const replyInviteRequest = {
      method: 'post',
      url: `${process.env.REACT_APP_API_ENDPOINT}/graphql/user`,
      json: true,
      headers: {
        'Authorization': `Bearer ${session.getToken()}`
      },
      data: {
        query: `
          mutation invitationComplete ($input: InvitationCompleteInput!) {
            invitationComplete (input: $input) {
              error
              data {
                projectId
              }
            }
          }
        `,
        variables: {
          input: {
            id: id,
            token: code,
            confirm: true
          }
        }
      }
    };

    axios(replyInviteRequest)
      .then((httpResponse) => {
        const { invitationComplete } = httpResponse.data.data;

        if (invitationComplete.error) {
          return reject(invitationComplete.error);
        }

        resolve(invitationComplete.data);
      })
      .catch(reject);
  });  
}

export const fetchProjectACL = async function (params) {
  return new Promise((resolve, reject) => {
    try {
      const { projectId } = params;

      const axiosRequestOptions = {
        method: 'post',
        url: 'https://ss8f1u17g1.execute-api.us-east-1.amazonaws.com/prod/graphql', //`${process.env.REACT_APP_API_ENDPOINT}/graphql/user`,
        json: true,
        headers: {
          'Authorization': `Bearer ${session.getToken()}`
        },
        data: {
          query: `
            query describeProjectACL($projectId: UUID!) {
              response: describeProjectACL(input: { projectId: $projectId }) {
                error
                data {
                  userId
                  projectId
                  scopes
                }
              }
            }
          `,
          variables: {
            projectId
          }
        }
      };

      axios
        .request(axiosRequestOptions)
        .then((httpResponse) => {
          const { response } = httpResponse.data.data;
          console.log(httpResponse);

          if (response.error) {
            return reject(response.error);
          }

          resolve(response.data);
        })
        .catch(reject);
    } catch (e) {
      reject(e);
    }
  });
}

export default {
  signIn,
  signUp,
  submitConfirmationCode,
  resendConfirmationCode,
  getAccessTokenUser,
  exchangeRefreshToken,
  isValidEmail,
  fetchInvite,
  answerInvite,
  fetchProjectACL
};