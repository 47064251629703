import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
//import validator from 'validator';

import { Box as Layout } from '../../components/Whitebox';
import SignUpForm from '../../components/SignUpForm';
//import ConfirmUserForm from '../../components/ConfirmUserForm';

import api from '../../helpers/api';
import validate from '../../validators/sign-up';

function getConfirmUrl (data, redirectUrl = null) {
  var query = new URLSearchParams();
  query.set('email', data.email);

  if (redirectUrl) {
    query.set('redirect', redirectUrl);
  }

  return `/confirm?${query.toString()}`;
}


function getErrors (validation) {
  if (!validation) {
    return [];
  }

  var errors = [];

  Object.keys(validation).forEach(([key, error]) => {
    if (error) {
      errors.push(error);
    }
  });

  return errors;
}
/*
function CConfirm (props) {
  const [ error, setError ] = React.useState(null);
  const [ sending, setSending ] = React.useState(false);
  const [ resendingCode, setResendingCode ] = React.useState(false);
  const [ formData, setFormData ] = React.useState({ email: 'william.wagner.br@gmail.com', code: '123456' });

  React.useEffect(() => {
    const listener = ({ payload }) => {
      if (payload.email === formData.email) {
        console.log('submit_email_confirmation_code_response', payload);
        setSending(false);
        setError(payload.error);
      }
    };

    Hub.listen('submit_email_confirmation_code_response', listener);

    return () => {
      Hub.remove('submit_email_confirmation_code_response', listener);
    }
  })

  var elementProps = {
    sending: sending,
    data: formData,
    resendingCode: resendingCode,
    error: error,
    onChange: (mutatedData) => {
      console.log('change', mutatedData);
      setFormData(mutatedData);
    },
    onSubmit: () => {
      setSending(true);
      Hub.dispatch('submit_email_confirmation_code', formData);
    },
    onResendCode: () => {
      setResendingCode(true);
      Hub.dispatch('resend_email_confirmation_code', { email: formData.email });
    }
  };

  return React.createElement(ConfirmUserForm, elementProps, null);
}
*/

const SignUpPage = (props) => {
  const search = new URLSearchParams(props.location.search);

  // States
  const [ confirm, setConfirm ] = React.useState(false);
  const [ error, setError ] = React.useState(null);
  const [ sending, setSending ] = React.useState(false);
  const [ validationErrors, setValidationErrors ] = React.useState({
    email: null,
    name: null,
    password: null,
    passwordConfirm: null
  });

  const [ form, setForm ] = React.useState({
    email: search.get('email'),
    name: '',
    password: '',
    passwordConfirm: ''
  });

  const [ touched, setTouched ] = React.useState({
    email: false,
    name: false,
    password: false,
    passwordConfirm: false
  });

  const handleSubmit = async () => {
    setSending(true);

    var signUpParams = {
      email: form.email,
      name: form.name,
      password: form.password
    };

    try {
      await api.signUp(signUpParams);
      setConfirm(true);
    } catch (e) {
      setError(e);
    }

    setSending(false);
  }

  const handleChange = (data) => {
    setForm(data);
    setValidationErrors(validate(data));
  }

  const handleBlur = (target) => {
    var newTouchedState = { ...touched };
    newTouchedState[target] = true;
    setTouched(newTouchedState);
  }

  const elementProps = {
    valid: getErrors(validationErrors).length === 0,
    sending: sending,
    data: form,
    errors: validationErrors,
    error: error,
    touched: touched,
    onSubmit: handleSubmit,
    onChange: handleChange,
    onBlur: handleBlur,
    emailInput: {
      disabled: true
    },
    cancelButton: {
      disabled: true
    }
  };

  if (confirm) {
    return (<Redirect to={getConfirmUrl(form, search.get('redirect'))} />);
  }

  return (
    <Layout>
      <SignUpForm {...elementProps} />
    </Layout>
  )
}

export default withRouter(SignUpPage);