import axios from 'axios';
import { call, put } from 'redux-saga/effects';

// Helpers
import { isValidUser } from '../../helpers/cognito';

async function fetchInvitationAsync (inviteId) {
  try {
    var invitationResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/invitations/${inviteId}`);

    if (invitationResponse.data.error) {
      throw new Error(invitationResponse.data.error);
    }

    const { projectId, email, requesterId } = invitationResponse.data.data;

    var requesterResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/${requesterId}`);
    var projectResponse = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/projects/${projectId}`);
    var isUserAlreadyRegistred = await isValidUser({ username: email, clientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID });
    
    return {
      error: null,
      data: {
        invite: invitationResponse.data,
        project: projectResponse.data,
        requester: requesterResponse.data,
        exists: isUserAlreadyRegistred
      }
    }
  } catch (e) {
    return {
      error: e
    }
  }
}

/**
 * Load invite data
 * @param {String} code 
 * @param {Object} api 
 */
function* fetchInvitation (action) {
  console.log('Fetching invite...');
  const { payload } = action;

  yield put({
    type: 'INVITE_FETCH_REQUEST',
    payload: {
      id: payload.id
    }
  });

  var response = yield call(fetchInvitationAsync, payload.id);

  var responseDispatchParams = {
    type: 'INVITE_FETCH_RESPONSE',
    payload: {
      id: payload.id,
      data: {
        loading: false,
        error: response.error,
        data: response.data
      }
    }
  };

  yield put(responseDispatchParams);
};

export default fetchInvitation;