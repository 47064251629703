import { useState, useEffect } from 'react';

import api from '../helpers/api';

const useFetchInvite = (inviteId) => {
  const [ loaded, setLoaded ] = useState(undefined);
  const [ loading, setLoading ] = useState(false);
  const [ data, setData ] = useState(undefined);
  const [ error, setError ] = useState(undefined);
  const isReady =  loaded && !loading;

  useEffect(() => {
    if (inviteId && inviteId.length > 0) {
      setLoading(true);
      setError(undefined);
      setData(undefined);

      api.fetchInvite({ id: inviteId })
        .then((response) => {
          if (response.error) {
            setError(response.error);
          } else {
            setData(response.data);
          }

          setLoaded(inviteId);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoaded(inviteId);
          setLoading(false);
        });
    }
  }, [ inviteId ]);

  return {
    isReady,
    loaded,
    loading,
    error,
    data
  };
}

export default useFetchInvite;